<template>
  <div>cloud</div>
</template>

<script>
    export default {
        name: "Cloud"
    }
</script>

<style scoped>

</style>
